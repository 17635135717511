import { Patient } from '@/models/Patient.ts';
import { Button } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { FC } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import PatientInfoItem from './PatientInfoItem.tsx';

interface Props {
    patient: Patient;
}

const PatientInformation: FC<Props> = ({ patient: patientInfo }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <Paragraph className="text-sm uppercase text-gray-500">Patienten informationen</Paragraph>
            <div className="flex flex-col gap-2">
                <PatientInfoItem label="Versicherungsart" value={patientInfo.insurance.type} />
                <PatientInfoItem label="Krankenversicherung" value={patientInfo.insurance.provider} />
                <PatientInfoItem label="Patienten Profil" value={patientInfo?.profile ? 'Ja' : 'Nein'} />
                <PatientInfoItem label="Kommunikation" value={patientInfo.profile.communication} />
                <PatientInfoItem label="Anmerkungen" value={patientInfo.profile.others} />
            </div>
            <div>
                <Button
                    variant="transparent"
                    className="px-0 font-semibold text-black"
                    onClick={() => console.debug('show edit')}
                    leftSection={<Pencil />}
                >
                    Patient Informationen öffnen
                </Button>
            </div>
        </div>
    );
};

export default PatientInformation;
