import { Button } from '@mantine/core';
import { Spotlight, spotlight } from '@mantine/spotlight';
import { Pencil, Search } from 'lucide-react';
import { FC, ReactElement, useMemo, useState } from 'react';

import Heading4 from '@/components/text/Heading4.tsx';

const options = [
    {
        practice: 'Hamburg',
        customer: 'Eterno Health',
    },
    {
        practice: 'Frankfurt',
        customer: 'Eterno Health',
    },
    {
        practice: 'Berlin',
        customer: 'Eterno Health',
    },
    {
        practice: 'HNO Rodenkirchen',
        customer: 'HNO Rodenkirchen & Kerpen',
    },
    {
        practice: 'HNO Kerpen',
        customer: 'HNO Rodenkirchen & Kerpen',
    },
];

// TODO add recommendation logic
const CustomerPracticeSelection: FC = () => {
    const [selected, setSelected] = useState({
        practice: 'Hamburg',
        customer: 'Eterno Health',
    });
    const [query, setQuery] = useState<string>('');

    const filtered_results: ReactElement[] = useMemo(() => {
        if (!query) {
            return options.map((item) => (
                <Spotlight.Action
                    onClick={() => setSelected(item)}
                    key={`${item.customer}_${item.practice}`}
                    label={item.practice}
                    description={item.customer}
                />
            ));
        }

        const formatted_query = query.toLowerCase().trim();

        const filtered_options = options.filter(
            (item) =>
                item.customer.toLowerCase().includes(formatted_query) ||
                item.practice.toLowerCase().includes(formatted_query)
        );

        return filtered_options.map((item) => (
            <Spotlight.Action
                key={`${item.customer}_${item.practice}`}
                label={item.practice}
                description={item.customer}
            />
        ));
    }, [query, options]);

    return (
        <>
            <div className="flex items-center">
                <Heading4 className="font-bold tracking-wider">{`${selected.customer} - ${selected.practice}`}</Heading4>
                <Button
                    classNames={{
                        section: 'mr-1',
                    }}
                    variant="transparent"
                    onClick={spotlight.open}
                    leftSection={<Pencil className="size-3.5" />}
                >
                    Ändern
                </Button>
            </div>
            <Spotlight.Root query={query} onQueryChange={setQuery}>
                <Spotlight.Search placeholder="Suche..." leftSection={<Search className="size-6" />} />
                <Spotlight.ActionsList>
                    {filtered_results.length > 0 ? (
                        filtered_results
                    ) : (
                        <Spotlight.Empty>Für diese Suche wurden keine Ergebnisse gefunden!</Spotlight.Empty>
                    )}
                </Spotlight.ActionsList>
            </Spotlight.Root>
        </>
    );
};

export default CustomerPracticeSelection;
