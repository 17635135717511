import { Document } from '@/models/Document.ts';
import { FC } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import DocumentItem from './DocumentItem.tsx';

interface Props {
    documents: Document[];
}

const DocumentExchange: FC<Props> = ({ documents }) => {
    return (
        <div className="flex w-full flex-col gap-4">
            <Paragraph className="text-sm uppercase text-gray-500">Austausch von Dokumenten</Paragraph>
            <section className="flex flex-col gap-2">
                {documents.map((document) => (
                    <DocumentItem document={document} key={document?.id} />
                ))}
            </section>
        </div>
    );
};

export default DocumentExchange;
