import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { clsx } from 'clsx';
import { FC } from 'react';

interface Props {
    status: IndicatorStatus;
    text: string;
    classNames?: {
        text?: string;
    };
}

const StatusLamp: FC<Props> = ({ status, text, classNames }) => {
    const status_color = {
        [IndicatorStatus.ACTIVE]: 'bg-green-500',
        [IndicatorStatus.INACTIVE]: 'bg-red-500',
        [IndicatorStatus.UNKNOWN]: 'bg-slate-300',
    };

    return (
        <div className="flex items-center gap-3">
            <div className={clsx('size-3 rounded-full', status_color[status])} />
            <p className={classNames?.text}>{text}</p>
        </div>
    );
};

export default StatusLamp;
