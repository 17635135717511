import { Appointment } from '@/models/Appointment.ts';
import { ActionIcon } from '@mantine/core';
import { Pencil } from 'lucide-react';
import { FC, useMemo } from 'react';

import EternoBadge from '@/components/EternoBadge.tsx';
import OverlaySection from '@/components/OverlaySection.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

import Paragraph from '../text/Paragraph.tsx';

interface Props {
    appointment: Appointment;
    onEdit: () => void;
}

const AppointmentDetails: FC<Props> = ({ appointment, onEdit }) => {
    const formatted_date = useMemo(() => dayjs(appointment.date).format('dddd, D MMMM YYYY'), [appointment]);
    const formatted_time = useMemo(() => dayjs(appointment.date).format('HH:mm'), [appointment]);

    return (
        <OverlaySection>
            <div className="flex w-full items-center justify-between gap-4">
                <div className="flex flex-col gap-1">
                    <div className="flex gap-2">
                        <Paragraph className="text-lg text-slate-500">{appointment.name} - 15 Min</Paragraph>
                        <EternoBadge text={appointment.status ?? ''} size="lg" backgroundColor="red" />
                    </div>
                    <div className="flex items-center gap-2">
                        <Paragraph className="font-md">
                            {formatted_date} um {formatted_time} mit {appointment.doctor.display_name.text}
                        </Paragraph>
                    </div>
                </div>
                <ActionIcon variant="transparent" aria-label="Settings" onClick={onEdit}>
                    <Pencil className="size-6 cursor-pointer text-black" />
                </ActionIcon>
            </div>
        </OverlaySection>
    );
};

export default AppointmentDetails;
