import { UseFormReturnType, useForm } from '@mantine/form';
import { FormEvent, ReactNode } from 'react';

// TODO: use zod as schema validation: https://mantine.dev/form/schema-validation/

export interface Props<T> {
    initialValues?: T;
    handleSubmit: (values: T, event: FormEvent<HTMLFormElement> | undefined) => void;
    children: (form: UseFormReturnType<T>) => ReactNode;
}

const Form = <T extends Record<string, unknown>>({ initialValues, handleSubmit, children }: Props<T>) => {
    const form = useForm<T>({
        mode: 'uncontrolled',
        initialValues: initialValues,
    });

    return <form onSubmit={form.onSubmit(handleSubmit)}>{children(form)}</form>;
};

export default Form;
