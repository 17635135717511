import { ComboboxItem, MultiSelect, SegmentedControl, Select, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Calendar, List, Search, SquareKanban } from 'lucide-react';
import { FC, useState } from 'react';

interface Props {
    practitioner_options: ComboboxItem[];
    room_options: ComboboxItem[];
}

const ListViewHeader: FC<Props> = ({ practitioner_options, room_options }) => {
    const [practitioner, setPractitioner] = useState<ComboboxItem>(practitioner_options[0]);
    const [rooms, setRooms] = useState<string[]>([]);
    const [date, setDate] = useState<Date | null>(new Date());

    return (
        <div className="flex gap-5">
            <TextInput
                placeholder="Name, Geb. Datum, Email, Telefon, DC ID"
                rightSectionPointerEvents="none"
                rightSection={<Search className="size-6" />}
                className="flex-grow"
            />
            <Select
                value={practitioner.value}
                onChange={(_value, option) => setPractitioner(option)}
                data={practitioner_options}
                className="flex-grow"
            />
            <MultiSelect
                value={rooms}
                data={room_options}
                onChange={setRooms}
                placeholder="Please select"
                className="flex-grow"
            />
            <DatePickerInput
                value={date}
                onChange={setDate}
                rightSection={<Calendar />}
                rightSectionPointerEvents="none"
                className="w-48"
            />
            <SegmentedControl
                value={'list'}
                disabled
                data={[
                    { label: <List />, value: 'list' },
                    { label: <SquareKanban />, value: 'kanban' },
                ]}
            />
        </div>
    );
};

export default ListViewHeader;
