import clsx from 'clsx';
import { Children, FC, ReactElement, ReactNode } from 'react';

import SelectableItem from './SelectableItem.tsx';
import Heading3 from './text/Heading3.tsx';

interface Props {
    title?: string;
    className?: string;
    children: ReactNode;
    handleSelect: (key: string) => void;
    selectedItem: string;
}

const SelectableList: FC<Props> = ({ title, className, children, handleSelect, selectedItem }) => {
    return (
        <div className={clsx('flex flex-col gap-3', className)}>
            {title && <Heading3>{title}</Heading3>}
            {Children.map(children, (child) => {
                const child_key = (child as ReactElement).key ?? '';

                return (
                    <SelectableItem
                        key={`wrapper_${child_key}`}
                        isSelected={selectedItem === child_key}
                        onClick={() => handleSelect(child_key)}
                    >
                        {child}
                    </SelectableItem>
                );
            })}
        </div>
    );
};

export default SelectableList;
