import { generateColors } from '@mantine/colors-generator';
import { createTheme } from '@mantine/core';

export const theme = createTheme({
    colors: {
        eterno: generateColors('#4c726d'),
    },
    primaryColor: 'eterno',
    primaryShade: 9,
});
