import { Loader } from '@mantine/core';
import { FC } from 'react';

// TODO: make component have dynamic size
// interface Props {
// size: number;
// }

const CircularLoader: FC = () => {
    return <Loader className={`size-16 after:size-16`} />;
};

export default CircularLoader;
