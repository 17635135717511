import { Children, FC, ReactElement, ReactNode } from 'react';

import Heading2 from '../text/Heading2.tsx';

export interface FormSectionProps {
    title?: string;
    children: ReactNode;
}

const FormSection: FC<FormSectionProps> = ({ title, children }) => (
    <>
        {title && (
            <div>
                <Heading2>{title}</Heading2>
            </div>
        )}
        <div className="flex flex-wrap justify-between gap-2">
            {Children.map(children, (child) => {
                const element_key = (child as ReactElement).key ?? '';

                return (
                    <div className="flex-[0_0_49%]" key={`wrapper_${element_key}`}>
                        {child}
                    </div>
                );
            })}
        </div>
    </>
);

export default FormSection;
