import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { SearchPatientItem } from '@/models/SearchPatientItem.ts';
import { Button, TextInput } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Search } from 'lucide-react';
import { FC, useState } from 'react';

import Paragraph from '@/components/text/Paragraph.tsx';

import SelectableList from '../SelectableList.tsx';
import Heading3 from '../text/Heading3.tsx';
import PatientRow from './PatientRow.tsx';

// TODO: add memoization
// TODO: move search patient outside and pass it as children
const SearchPatient: FC = () => {
    // TODO: test data
    const search_result: SearchPatientItem[] = [
        {
            id: randomId(),
            name: 'Manuel Fischer',
            practices: [{ id: randomId(), status: IndicatorStatus.ACTIVE, text: 'Derma Hamburg' }],
            insurances: ['GKV: idk', 'GKV: ARAG', 'GKV: Allianz'],
            date_of_birth: new Date('04.08.1975'),
            dc_instance: 'DC 1234',
        },
        {
            id: randomId(),
            name: 'Manuel Fischer',
            practices: [
                { id: randomId(), status: IndicatorStatus.ACTIVE, text: 'Gym am Fleet' },
                { id: randomId(), status: IndicatorStatus.INACTIVE, text: 'Margrit Riede' },
            ],
            insurances: ['GKV', 'GKV: Z'],
            date_of_birth: new Date('04.08.1975'),
            dc_instance: 'DC 7745',
        },
        {
            id: randomId(),
            name: 'Manuel Schmidt',
            practices: [
                { id: randomId(), status: IndicatorStatus.UNKNOWN, text: 'Gym am Fleet' },
                { id: randomId(), status: IndicatorStatus.UNKNOWN, text: 'Margrit Riede' },
            ],
            insurances: ['PKV', 'GKV'],
            date_of_birth: new Date('12.11.1986'),
            dc_instance: 'DC 4432',
        },
        {
            id: randomId(),
            name: 'Manuel Diaz',
            practices: [{ id: randomId(), status: IndicatorStatus.UNKNOWN, text: 'Margrit Riede' }],
            insurances: ['Selbstzahler'],
            date_of_birth: new Date('06.11.2000'),
        },
    ];

    const [selected_item, setSelectedItem] = useState<string>('');

    const handleSelect = (value: string) => {
        setSelectedItem(selected_item === value ? '' : value);
    };

    return (
        <div className="flex w-full flex-col gap-3">
            <Heading3>Search Patient</Heading3>
            <div className="flex justify-stretch gap-3">
                <TextInput
                    className="w-3/4"
                    placeholder="Patient name"
                    rightSectionPointerEvents="none"
                    rightSection={<Search className="size-5" />}
                />
                <Button className="w-1/4" variant="filled">
                    Search
                </Button>
            </div>
            {search_result ? (
                <SelectableList handleSelect={handleSelect} selectedItem={selected_item}>
                    {search_result.map((patient) => (
                        <PatientRow patient={patient} key={patient.id} />
                    ))}
                </SelectableList>
            ) : (
                <div className="flex h-48 w-full items-center justify-center rounded-md bg-gray-100">
                    <Paragraph className="text-center text-gray-500">Keine Patient gefunden</Paragraph>
                </div>
            )}
        </div>
    );
};

export default SearchPatient;
