import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

const OverlaySection: FC<Props> = ({ children }) => {
    return (
        <div className="flex flex-col items-center justify-between gap-4 rounded-lg border bg-white p-4">
            {children}
        </div>
    );
};

export default OverlaySection;
