import { Button } from '@mantine/core';
import clsx from 'clsx';
import { LucideIcon } from 'lucide-react';
import { FC } from 'react';

import Paragraph from './text/Paragraph.tsx';

interface Props {
    icon: LucideIcon;
    text: string;
    classNames?: {
        icon?: string;
        text?: string;
    };
}

const IconButton: FC<Props> = ({ icon: Icon, text, classNames }) => {
    return (
        <Button variant="transparent" className="h-auto">
            <div className="flex flex-col items-center gap-2">
                <Icon className={clsx('size-6 text-black', classNames?.icon)} />
                <Paragraph className={clsx('text-black', classNames?.text)}>{text}</Paragraph>
            </div>
        </Button>
    );
};

export default IconButton;
