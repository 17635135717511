import { FC } from 'react';
import QRCode from 'react-qr-code';

import Heading1 from '@/components/text/Heading1.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

export interface Props {
    code: string;
}

const forms_url = `https://dev.patients.eterno-health.io/all-forms-code?user=bart_simpson`;

const FormQRCode: FC<Props> = ({ code }) => (
    <div className="flex flex-col items-center gap-8">
        <QRCode value={forms_url} />
        <Paragraph>Haben Sie Probleme mit dem Lesegerät? Geben Sie den Code manuell ein</Paragraph>
        <Heading1>{code}</Heading1>
    </div>
);

export default FormQRCode;
