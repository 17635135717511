import orderBy from 'lodash.orderby';
import { useMemo, useState } from 'react';

interface ToBeSorted<T> {
    field: keyof T;
    is_descending: boolean;
}

interface SortObjectsArgs<T> {
    unsorted: T[];
    default_field: keyof T;
    is_descending_by_default?: boolean;
}

const useSortObjects = <T>({ unsorted, default_field, is_descending_by_default = false }: SortObjectsArgs<T>) => {
    const [sort_by, setSortBy] = useState<ToBeSorted<T>>({
        field: default_field,
        is_descending: is_descending_by_default,
    });

    const sorted = useMemo(
        () => orderBy(unsorted, [sort_by.field], [sort_by.is_descending ? 'desc' : 'asc']),
        [unsorted, sort_by]
    );

    return {
        sorted,
        setSortBy,
    };
};

export default useSortObjects;
