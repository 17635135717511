import { Button } from '@mantine/core';
import { IdCard, TriangleAlert } from 'lucide-react';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import CircularLoader from '@/components/CircularLoader.tsx';
import VerticalIconWithText from '@/components/VerticalIconWithText.tsx';

import SelectableList from './SelectableList.tsx';
import Paragraph from './text/Paragraph.tsx';

enum CardReaderStep {
    SELECT_READER = 'SELECT_READER',
    INSERT_CARD = 'INSERT_CARD',
    RETRY = 'RETRY',
    LOADING = 'LOADING',
}

// TODO: double check if this type make sense and if so move it into model folder
type CardReaderInfo = {
    id: string;
    name: string;
};

// TODO: double check if this type make sense and if so move it into model folder
type CardReaderGroup = {
    id: string;
    name: string;
    readers: CardReaderInfo[];
};

const CardReader: FC = () => {
    // TODO: fetch this data properly
    const card_reader_list: CardReaderGroup[] = [
        {
            id: '1',
            name: 'Gym and Fleet',
            readers: [
                {
                    id: '13',
                    name: 'Dr. med. Susanne Tolkemitt',
                },
                {
                    id: '14',
                    name: 'Dr. med. Marek Grant',
                },
                {
                    id: '15',
                    name: 'Dr. med. Sabine Linden',
                },
            ],
        },
        {
            id: '2',
            name: 'Margrit Riede',
            readers: [
                {
                    id: '21',
                    name: 'Margrit Riede',
                },
            ],
        },
    ];

    const [step, setStep] = useState<CardReaderStep>(CardReaderStep.SELECT_READER);
    const [selected_item, setSelectedItem] = useState<string>('');

    const handleSelect = (value: string) => {
        setSelectedItem(selected_item === value ? '' : value);
        setStep(CardReaderStep.LOADING); // TODO: actual logic
    };

    useEffect(() => {
        if (selected_item) {
            // TODO: actual card reading
            const read_card_timeout = setTimeout(() => {
                setStep(CardReaderStep.RETRY);
            }, 3000);

            return () => {
                clearTimeout(read_card_timeout);
            };
        }
    }, [selected_item]);

    const render_step: ReactNode = useMemo(() => {
        switch (step) {
            case CardReaderStep.SELECT_READER:
                return (
                    <div className="flex flex-col gap-2">
                        {card_reader_list.map((group: CardReaderGroup) => (
                            <SelectableList
                                key={group.id}
                                title={group.name}
                                handleSelect={handleSelect}
                                selectedItem={selected_item}
                            >
                                {group.readers.map((reader) => (
                                    <div key={reader.id}>
                                        <Paragraph>{reader.name}</Paragraph>
                                    </div>
                                ))}
                            </SelectableList>
                        ))}
                    </div>
                );
            case CardReaderStep.INSERT_CARD:
                return (
                    <VerticalIconWithText
                        icon={<IdCard className="size-16" />}
                        text="Stecken Sie die Karte in das Terminal"
                    />
                );
            case CardReaderStep.RETRY:
                return (
                    <VerticalIconWithText
                        icon={<TriangleAlert className="size-16" />}
                        text="Stecken Sie die Karte in das Terminal"
                    >
                        <Button onClick={() => setStep(CardReaderStep.LOADING)}>
                            Es gab ein Problem beim Lesen der Karte
                        </Button>
                    </VerticalIconWithText>
                );
            case CardReaderStep.LOADING:
                return (
                    <VerticalIconWithText icon={<CircularLoader />} text="Karte wird gelesen..."></VerticalIconWithText>
                );
        }
    }, [step, selected_item]);

    return <div>{render_step}</div>;
};

export default CardReader;
