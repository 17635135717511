import { clsx } from 'clsx';
import { Transition, Variants, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
    className?: string;
    children: ReactNode;
    fullScreen?: boolean;
}

// Define your combined fade and slide animation variants
const slide_variants: Variants = {
    initial: { opacity: 0, y: 25 }, // Start slightly below and transparent
    in: { opacity: 1, y: 0 }, // Fade in and move to original position
    out: { opacity: 0, y: 25 }, // Fade out and move downward
};

const slide_transition: Transition = {
    duration: 0.3, // Adjust the duration as needed
};

const Page: FC<Props> = ({ children, className, fullScreen = false }) => {
    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={slide_variants}
            transition={slide_transition}
            className={twMerge(
                clsx('flex flex-col items-center', fullScreen ? 'h-screen w-screen' : 'h-full w-full', className)
            )}
        >
            {children}
        </motion.div>
    );
};

export default Page;
