import { IndicatorStatus } from '@/models/IndicatorStatus.ts';

// mock interface
export interface Appointment {
    id: string;
    name: string;
    date: Date;
    doctor: {
        display_name: { text: string };
    };
    status?: string;
}

export enum Insurance {
    PUBLIC = 'GKV',
    PRIVATE = 'PKV',
    SELF_PAYER = 'Selbszahler',
}

interface Requirement {
    status: IndicatorStatus;
    text: string;
}

export enum AppointmentStatus {
    IN_ROOM = 'Zimmer',
    ONLINE = 'Online',
    WAITING = 'Im Warteraum',
    CHECKING_IN = 'Im Check-in',
    EXPECTED = 'Erwartet',
    DONE = 'Fertig',
    CANCELLED = 'Abgesagt',
}

// TODO: To be merged with the `Appointment` interface
export interface AppointmentRow {
    id: string;
    patient_name: string;
    insurance: Insurance;
    practitioner: string;
    appointment_type: string;
    expected_time: string;
    arrival_time?: string;
    requirements: Requirement[];
    dc_profile: string;
    status: AppointmentStatus;
    room_number?: number;
}
