import { IndicatorStatus } from '@/models/IndicatorStatus.ts';
import { RoomOption } from '@/models/RoomOptions.ts';
import { Button, Divider, Select } from '@mantine/core';
import { FileScan, Mail, Printer, QrCode } from 'lucide-react';
import { FC } from 'react';

import IconButton from '@/components/IconButton.tsx';
import OverlaySection from '@/components/OverlaySection.tsx';
import StatusLamp from '@/components/StatusLamp.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

interface Props {
    roomOptions: RoomOption[];
    onDischarge: () => void;
    onDischargeAndFollowUp: () => void;
}

const PatientActions: FC<Props> = ({ onDischarge, onDischargeAndFollowUp, roomOptions }) => {
    return (
        <OverlaySection>
            <div className="flex w-full flex-col gap-5">
                <Select
                    label="Zimmer"
                    placeholder="Zimmer wählen"
                    data={roomOptions}
                    defaultValue="1"
                    className="w-full"
                />
                <div className="flex w-full justify-between gap-2">
                    <Button variant="outline" className="w-1/2" onClick={onDischargeAndFollowUp}>
                        Entlassen und Folgetermin
                    </Button>
                    <Button className="w-1/2" onClick={onDischarge}>
                        Patient entlassen
                    </Button>
                </div>
            </div>
            <div className="flex-start mt-1 w-full">
                <Paragraph className="text-sm uppercase text-gray-500">Dokumenten</Paragraph>
            </div>
            <div className="flex w-full justify-between gap-2">
                <div className="flex justify-between gap-4">
                    <StatusLamp status={IndicatorStatus.ACTIVE} text="Anamnese" />
                    <StatusLamp status={IndicatorStatus.ACTIVE} text="Datenschutzerklärung" />
                </div>
                <a href="https://eterno.health" className="text-sm font-medium underline">
                    Bearbeiten
                </a>
            </div>
            <Divider className="w-full" />
            <section className="flex w-full justify-around">
                <IconButton icon={FileScan} text="Datei einscannen" />
                <IconButton icon={Mail} text="Nachricht senden" />
                <IconButton icon={Printer} text="Ticket ausdrucken" />
                <IconButton icon={QrCode} text="QR-Code anzeigen" />
            </section>
        </OverlaySection>
    );
};

export default PatientActions;
