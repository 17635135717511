import { SearchPatientItem } from '@/models/SearchPatientItem.ts';
import { FC, useMemo } from 'react';

import { dayjs } from '@/utils/dayjsSetup.ts';

import StatusLamp from '../StatusLamp.tsx';
import Paragraph from '../text/Paragraph.tsx';

interface Props {
    patient: SearchPatientItem;
}

const PatientRow: FC<Props> = ({ patient }) => {
    const formatted_date = useMemo(() => dayjs(patient.date_of_birth).format('DD MMM YYYY'), [patient]);

    const name_instance = `${patient.name} ${patient.dc_instance ? `(${patient.dc_instance})` : ''}`;

    return (
        <div className="flex flex-row">
            <Paragraph className="w-1/4">{name_instance}</Paragraph>
            <div className="flex w-1/4 flex-col">
                {patient.practices.map((practice) => {
                    return <StatusLamp key={practice.id} status={practice.status} text={practice.text} />;
                })}
            </div>
            {patient.insurances && (
                <div className="flex w-1/4 flex-col">
                    {/*TODO use id*/}
                    {patient.insurances.map((insurance) => (
                        <Paragraph key={insurance}>{insurance}</Paragraph>
                    ))}
                </div>
            )}
            <Paragraph className="w-1/4">{formatted_date}</Paragraph>
        </div>
    );
};

export default PatientRow;
