import { Appointment } from '@/models/Appointment.ts';
import { Document } from '@/models/Document.ts';
import { Patient } from '@/models/Patient.ts';
import { RoomOption } from '@/models/RoomOptions.ts';
import { Button, Divider } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { FC } from 'react';

import BaseModal from '@/components/BaseModal.tsx';
import EternoBadge from '@/components/EternoBadge.tsx';
import ExternalLink from '@/components/ExternalLink.tsx';
import Heading1 from '@/components/text/Heading1.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import AppointmentDetails from './AppointmentDetails.tsx';
import AppointmentHistory from './AppointmentHistory.tsx';
import DocumentExchange from './DocumentExchange.tsx';
import PatientActions from './PatientActions.tsx';
import PatientInformation from './PatientInformation.tsx';

interface Props {
    opened: boolean;
    onClose: () => void;
}

const PatientModal: FC<Props> = ({ opened, onClose }) => {
    // TODO: Remove fake data and replace it with real data
    const fake_appointment: Appointment = {
        id: randomId(),
        name: 'Konsultation',
        date: new Date('1975-11-17'),
        doctor: { display_name: { text: 'Dr. Müller' } },
        status: 'Hoch',
    };

    const room_options: RoomOption[] = [
        { value: '1', label: 'Zimmer 1' },
        { value: '2', label: 'Zimmer 2' },
        { value: '3', label: 'Zimmer 3' },
        { value: '4', label: 'Zimmer 4' },
        { value: '5', label: 'Zimmer 5' },
    ];

    const documents: Document[] = [
        { id: randomId(), name: 'Überweisungsschein-Dermatologie-August_2024.jpg', date: new Date('2024-08-31') },
        { id: randomId(), name: 'Labor_August-2024.jpg', date: new Date('2024-08-31') },
        { id: randomId(), name: 'Überweisungsschein-Dermatologie-Juli_2024.jpg', date: new Date('2024-07-31') },
    ];

    const patient_appointments: Appointment[] = [
        {
            id: '1',
            name: '',
            date: new Date(2024, 7, 31, 10, 30), // 31. August 2024, 10:30 AM
            doctor: {
                display_name: { text: 'Dr. med. Susanne Tolkemitt' },
            },
        },
        {
            id: '2',
            name: '',
            date: new Date(2024, 7, 8, 14, 0), // 08 August 2024, 2:00 PM
            doctor: {
                display_name: { text: 'Dr. med. Susanne Tolkemitt' },
            },
        },
        {
            id: '3',
            name: '',
            date: new Date(2024, 6, 23, 9, 0), // 23 July 2024, 9:00 AM
            doctor: {
                display_name: { text: 'Dr. med. Susanne Tolkemitt' },
            },
        },
    ];

    const patient_information: Patient = {
        insurance: {
            type: 'GKV',
            provider: 'BAMER',
        },
        profile: {
            firstName: 'Max',
            lastName: 'Mustermann',
            dateOfBirth: new Date('01 Jan 1980'),
        },
    };

    return (
        <BaseModal
            opened={opened}
            onClose={onClose}
            title={
                <div className="flex items-center gap-2">
                    <Heading1>Max Mustermann</Heading1>
                    <EternoBadge text="eGK gelesen" size="lg" />
                </div>
            }
            size="xl"
            classNames={{
                header: 'pb-0',
            }}
        >
            <div className="flex flex-col gap-5">
                <div className="flex items-center gap-4 bg-slate-50">
                    <div className="flex gap-1">
                        <Paragraph>DC ID:</Paragraph>
                        <Paragraph>4567</Paragraph>
                    </div>
                    <Paragraph>17.11.75</Paragraph>
                    <ExternalLink
                        text="Patientenakte"
                        href="https://www.eterno.health"
                        classNames={{ anchor: 'text-black' }}
                    />
                    <Button variant="outline">eGK lesen</Button>
                </div>
                <AppointmentDetails appointment={fake_appointment} onEdit={() => {}} />
                <PatientActions
                    roomOptions={room_options}
                    onDischarge={() => console.debug('discharge')}
                    onDischargeAndFollowUp={() => console.debug('discharge and follow up')}
                />
                <DocumentExchange documents={documents} />
                <Divider className="w-full" />
                <section className="flex gap-5">
                    <PatientInformation patient={patient_information} />
                    <Divider orientation="vertical" className="h-auto" />
                    <AppointmentHistory appointments={patient_appointments} />
                </section>
            </div>
        </BaseModal>
    );
};

export default PatientModal;
