import { Bell, Calendar, Layers, List } from 'lucide-react';
import { Navigate } from 'react-router-dom';

import CalendarViewPage from '@/pages/CalendarViewPage.tsx';
import LandingPage from '@/pages/LandingPage.tsx';
import ListViewPage from '@/pages/ListViewPage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import MagicLinkPage from '@/pages/MagicLinkPage.tsx';

export const authenticated_routes = [
    {
        path: '/',
        element: <LandingPage />,
        name: 'Front Office',
        key: 'front-office',
        icon: <Bell />,
    },
    {
        path: '/',
        element: <LandingPage />,
        name: 'Back Office',
        key: 'back-office',
        icon: <Layers />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
    {
        path: '/list-view',
        element: <ListViewPage />,
        name: 'List View',
        key: 'list-view',
        icon: <List />,
    },
    {
        path: '/calendar-view',
        element: <CalendarViewPage />,
        name: 'Calendar View',
        key: 'calendar-view',
        icon: <Calendar />,
    },
];

export const unauthenticated_routes = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/magic-link',
        element: <MagicLinkPage />,
    },
    {
        path: '*',
        element: <Navigate to="/" replace />,
    },
];
