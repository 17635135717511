import { Card } from '@mantine/core';
import { clsx } from 'clsx';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    isSelected: boolean;
    onClick: () => void;
    className?: string;
}

const SelectableItem: FC<Props> = ({ children, isSelected, onClick, className }) => {
    return (
        <Card
            onClick={onClick}
            className={clsx(
                'cursor-pointer select-none rounded-lg border p-4 transition ease-in-out active:translate-y-0.5',
                isSelected ? 'border-green-500 bg-green-100' : 'border-gray-300 bg-white text-gray-700',
                className
            )}
        >
            {children}
        </Card>
    );
};

export default SelectableItem;
