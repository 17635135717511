import { Divider, Modal } from '@mantine/core';
import { clsx } from 'clsx';
import { X } from 'lucide-react';
import { FC, ReactNode } from 'react';

interface Props {
    opened: boolean;
    onClose: () => void;
    title?: ReactNode;
    children: ReactNode;
    size?: string | number;
    classNames?: {
        header: string;
    };
    footer?: ReactNode;
}

// TODO: create BaseModalBody and BaseModalFooter components
const BaseModal: FC<Props> = ({ opened, onClose, title, children, size = 'lg', classNames, footer }) => {
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size={size}
            centered
            withCloseButton
            closeButtonProps={{
                icon: <X />,
            }}
            classNames={{
                header: clsx('bg-slate-50', classNames?.header),
                title: 'bg-slate-50 text-lg font-bold',
                content: 'rounded-2xl bg-slate-50 p-3',
            }}
            title={title}
        >
            {children}
            {footer && (
                <>
                    <Divider className="my-3" />
                    <div
                        className={clsx(
                            'flex w-full flex-row',
                            Array.isArray(footer) ? 'justify-between' : 'justify-end'
                        )}
                    >
                        {footer}
                    </div>
                </>
            )}
        </Modal>
    );
};

export default BaseModal;
