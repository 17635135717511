import { Document } from '@/models/Document.ts';
import { FC, useMemo } from 'react';

import ExternalLink from '@/components/ExternalLink.tsx';
import Paragraph from '@/components/text/Paragraph.tsx';

import { dayjs } from '@/utils/dayjsSetup.ts';

interface Props {
    document: Document;
}

const DocumentItem: FC<Props> = ({ document }) => {
    const formatted_date = useMemo(() => dayjs(document.date).format('DD MMM YYYY'), [document]);

    return (
        <div className="center flex w-full justify-between">
            <ExternalLink classNames={{ anchor: 'text-black', icon: 'text-black' }} href={''} text={document.name} />
            <Paragraph className="text-slate-500">{formatted_date}</Paragraph>
        </div>
    );
};

export default DocumentItem;
