import { AppointmentStatus, Insurance } from '@/models/Appointment.ts';
import { IndicatorStatus } from '@/models/IndicatorStatus.ts';

export const appointments = {
    in_progress: [
        {
            id: 'd222bc32-162d-4ba7-8b1e-35e216eb60e8',
            patient_name: 'Christina Lüdders',
            expected_time: '2024-10-30T11:03:53.837Z',
            arrival_time: '2024-10-30T11:10:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Vorsorgeuntersuchung',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.IN_ROOM,
            room_number: 1,
        },
        {
            id: '21b2caca-1b46-4fea-abf6-c14abe36c6b2',
            patient_name: 'Martha Sinders',
            expected_time: '2024-10-30T11:10:53.837Z',
            arrival_time: '2024-10-30T11:15:53.837Z',
            insurance: Insurance.PRIVATE,
            practitioner: 'Margrit Riede',
            appointment_type: 'Erstgespräch',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.IN_ROOM,
            room_number: 2,
        },
        {
            id: 'e284f693-1d68-4c92-b828-b978a642c537',
            patient_name: 'Marc Vogel',
            expected_time: '2024-10-30T11:20:53.837Z',
            arrival_time: '2024-10-30T11:30:53.837Z',
            insurance: Insurance.SELF_PAYER,
            practitioner: 'Dr. med. Sabine Linden',
            appointment_type: 'Impftermin',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.ONLINE,
        },
        {
            id: '2662812c-29ad-415c-a39d-ff6fa5610aff',
            patient_name: 'Nicole Schmidt',
            expected_time: '2024-10-30T11:33:53.837Z',
            arrival_time: '2024-10-30T11:35:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Erstgespräch',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.WAITING,
        },
        {
            id: '6468489f-9a84-491d-9591-22225a1f8753',
            patient_name: 'Simon Fischer',
            expected_time: '2024-10-30T11:40:53.837Z',
            arrival_time: '2024-10-30T11:40:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Vorsorgeuntersuchung',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.CHECKING_IN,
        },
    ],
    expected: [
        {
            id: 'cc6a0bff-a1cf-4e72-931b-035414d40fcd',
            patient_name: 'Simon Fischer',
            expected_time: '2024-10-30T11:40:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Vorsorgeuntersuchung',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.INACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.ONLINE,
        },
        {
            id: 'f7f928b5-7df4-4c43-b5f8-bf7ffab153a8',
            patient_name: 'Simon Fischer',
            expected_time: '2024-10-30T11:40:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Vorsorgeuntersuchung',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.UNKNOWN,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.EXPECTED,
        },
        {
            id: 'f7e266ed-36e3-4f6f-81b1-e61f9ab0e4b2',
            patient_name: 'Simon Fischer',
            expected_time: '2024-10-30T11:40:53.837Z',
            insurance: Insurance.PUBLIC,
            practitioner: 'Dr. med. Susanne Tolkemitt',
            appointment_type: 'Vorsorgeuntersuchung',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.EXPECTED,
        },
    ],
    dismissed: [
        {
            id: 'd9cdac83-b664-419b-85c2-0913f21c3681',
            patient_name: 'Martha Sinders',
            expected_time: '2024-10-30T11:10:53.837Z',
            arrival_time: '2024-10-30T11:15:53.837Z',
            insurance: Insurance.PRIVATE,
            practitioner: 'Margrit Riede',
            appointment_type: 'Erstgespräch',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.DONE,
            room_number: 2,
        },
        {
            id: '43026c84-96a3-4902-8f68-c180d926e544',
            patient_name: 'Martha Sinders',
            expected_time: '2024-10-30T11:10:53.837Z',
            arrival_time: '2024-10-30T11:15:53.837Z',
            insurance: Insurance.PRIVATE,
            practitioner: 'Margrit Riede',
            appointment_type: 'Erstgespräch',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.DONE,
            room_number: 2,
        },
        {
            id: '21b2caca-1b46-4fea-abf6-c14abe36c6b2',
            patient_name: 'Martha Sinders',
            expected_time: '2024-10-30T11:10:53.837Z',
            insurance: Insurance.PRIVATE,
            practitioner: 'Margrit Riede',
            appointment_type: 'Erstgespräch',
            requirements: [
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'eGK eingelesen',
                },
                {
                    status: IndicatorStatus.ACTIVE,
                    text: 'Dokumente ausgefüllt',
                },
            ],
            dc_profile: 'https://google.com',
            status: AppointmentStatus.CANCELLED,
            room_number: 2,
        },
    ],
};
